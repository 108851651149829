import styles from '../RegistrationsRemainder/index.module.css'
import cn from 'classnames'
import { useRouter } from 'next/router'
import fetcher from '@lib/fetcher'
import { useCallback, useState, useMemo } from 'react'
import { getAppName } from '../../const'

const RegistrationsActivate = ({
  data,
  gs,
  registerEmail,
  onActivateBack,
  onActivateGet,
}) => {
  const s = { ...styles, ...gs }
  const [resendLoading, setResendLoading] = useState(false)
  const [resendStatus, setResendStatus] = useState('')
  const [countDownTime, setCountDownTime] = useState(0)
  const { locale } = useRouter()

  const app = useMemo(() => {
    return getAppName(locale)
  }, [locale])

  const handleSendAgain = useCallback(async () => {
    setResendLoading(true)
    const res = await fetcher({
      locale,
      url: '/api/multipass/account/users/send_validation_email',
      method: 'POST',
      body: {
        email: registerEmail,
        app,
      },
      needRecaptcha: true,
      recaptchaAction: 'user',
    }).finally(() => setResendLoading(false))
    if (res.status > 100 && res.status < 300) {
      setResendStatus('success')
      setCountDownTime(data?.countDownTime)

      let timer = setInterval(() => {
        setCountDownTime((prev) => {
          if (prev <= 0) {
            clearInterval(timer)
            return 0
          }
          return prev - 1
        })
      }, 1000)
    } else {
      setResendStatus('error')
    }
    let timer = setTimeout(() => {
      setResendStatus('')
      clearTimeout(timer)
    }, 5000)
  }, [locale, registerEmail, app, data?.countDownTime])

  return (
    <registrations-activate className={s.registrations_activate}>
      <div className={s.registrations_reminder_wrap}>
        <div className={s.registrations_reminder_head}>
          <p className={cn(s.registrations_title, s.rsr_title)}>
            {data?.activate_title}
          </p>
          <p
            className={s.rsr_desc}
            dangerouslySetInnerHTML={{ __html: data?.activate_desc }}
          />
          <span className={cn(s.rsr_desc, s.rsr_desc_email)}>
            {registerEmail}
          </span>
        </div>
        <div className={s.registrations_reminder_body}>
          <ul
            className={s.rsr_content}
            dangerouslySetInnerHTML={{ __html: data?.activate_content }}
          />
        </div>
        <div className={s.registrations_reminder_footer}>
          <p className={s.rsr_again_text}>{data?.activate_again}&nbsp;</p>
          {countDownTime > 0 ? (
            <>
              <span>{data?.retrieveText}</span>
              &nbsp;<span>{countDownTime}</span>&nbsp;
              <span>{data?.secondsText}</span>
            </>
          ) : (
            <a
              onClick={handleSendAgain}
              className={cn(s.rsr_send_again_text, {
                ['pointer-events-none']: resendLoading,
              })}
            >
              {data?.send_again_text}
            </a>
          )}
          <div className={s.rsr_btn_wrap}>
            <button
              onClick={onActivateBack}
              className={cn(s.rsr_btn, s.registrations_btn, s.rsr_btn_again)}
            >
              {data.activate_return}
            </button>
            <button
              onClick={onActivateGet}
              className={cn(s.rsr_btn, s.registrations_btn, s.rsr_btn_get)}
            >
              {data?.activate_get}
            </button>
          </div>
        </div>
        {resendStatus && (
          <div className={s.rsr_tip}>
            <div className={s.rsr_tip_icon_wrap}>
              {resendStatus === 'success' && (
                <span className={s.rsr_tip_info_icon}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_205_16388)">
                      <circle cx="10" cy="10" r="10" fill="#1677FF" />
                      <path
                        d="M10 16L10 10"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <circle
                        cx="10"
                        cy="6.5"
                        r="1.5"
                        transform="rotate(-180 10 6.5)"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_205_16388">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              )}
              {resendStatus === 'error' && (
                <span className={s.rsr_tip_error_icon}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_205_16236)">
                      <circle cx="10" cy="10" r="10" fill="#F84D4F" />
                      <path
                        d="M10 5L10 11"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <circle cx="10" cy="14.5" r="1.5" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_205_16236">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              )}
            </div>
            <p className={s.rsr_tip_text}>
              {resendStatus === 'success'
                ? data?.activate_send_tip
                : data?.activate_lock_tip}
            </p>
          </div>
        )}
      </div>
    </registrations-activate>
  )
}

export default RegistrationsActivate

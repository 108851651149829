import styles from './index.module.css'
import cn from 'classnames'
import { useCallback, useEffect, useState, useMemo } from 'react'
import clipboard from 'copy-to-clipboard'
import registrationsTrack from '../../RegistrationsTrack'
import fetcher from '@lib/fetcher'
import { useRouter } from 'next/router'
import { getAppName } from '../../const'

const RegistrationsCoupons = ({
  data,
  gs,
  coupons = [],
  onCouponsGet,
  registerEmail,
  showGoogleLoginCoupon,
}) => {
  const s = { ...styles, ...gs }
  const [dataSource, setDataSource] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)
  const [countDownTime, setCountDownTime] = useState(0)
  const [resend, setResend] = useState(false)
  const { locale } = useRouter()

  useEffect(() => {
    setDataSource(
      coupons.map((coupon) => {
        return {
          ...coupon,
          copied: false,
        }
      })
    )
  }, [coupons])

  const app = useMemo(() => {
    return getAppName(locale)
  }, [locale])

  const handleSendVerifyEmail = useCallback(async () => {
    setSubmitLoading(true)
    const res = await fetcher({
      locale,
      url: '/api/multipass/account/users/send_validation_email',
      method: 'POST',
      body: {
        email: registerEmail,
        app,
      },
      needRecaptcha: true,
      recaptchaAction: 'user',
    }).catch((e) => setSubmitLoading(false))
    if (res.status > 100 && res.status < 300) {
      setResend(true)
      setCountDownTime(data?.countDownTime)
      // start_ai_generated
      let timer = setInterval(() => {
        setCountDownTime((prev) => {
          if (prev <= 0) {
            setSubmitLoading(false)
            clearInterval(timer)
            return 0
          }
          return prev - 1
        })
      }, 1000)
      let tipTimer = setTimeout(() => {
        setResend(false)
        clearTimeout(tipTimer)
      }, 5000)
      // end_ai_generated
    }
  }, [app, locale, data?.countDownTime, registerEmail])

  const onCodeCopy = useCallback((code, index) => {
    registrationsTrack.useCodeTrack()
    setDataSource((prev) =>
      prev.map((item, idx) => {
        if (idx === index) {
          item.copied = true
        }
        return item
      })
    )
    clipboard(code)
    const copyTimer = setTimeout(() => {
      setDataSource((prev) =>
        prev.map((item, idx) => {
          if (idx === index) {
            item.copied = false
          }
          return item
        })
      )
      clearTimeout(copyTimer)
    }, 2000)
  }, [])

  return (
    <registrations-coupons class={s.registrations_coupons}>
      <div className={s.registrations_coupons_wrap}>
        <p className={cn(s.registrations_title, s.rsc_title)}>{data.title}</p>
        <div className={s.rsc_coupons_list_wrap}>
          <p className={s.rsc_coupons_label}>
            {data.label}
            <span className={s.coupons_num}> ({coupons.length})</span>
          </p>
          <ul
            className={cn(s.rsc_coupons_list, {
              ['min-md:!px-[32px]']: ['ca', 'eu-de'].includes(locale),
            })}
          >
            {dataSource.map((item, index) => {
              return (
                <li
                  key={item.shopify_id + index}
                  className={s.rsc_coupons_item}
                >
                  <div className={s.rsc_coupons_item_wrap}>
                    <div className={s.rsc_coupons_off}>
                      <div className={s.rsc_coupons_off_info}>
                        {item?.value_type === 'percentage' && (
                          <div className={s.rsc_coupons_off_percentage}>
                            <div
                              className={cn(s.rsc_coupons_off_percentage_num)}
                            >
                              {Math.abs(Number(item.value))}
                              <span className={s.rsc_coupons_off_fixed_unit}>
                                {data?.percentage_text}
                              </span>
                            </div>
                            <div
                              className={cn(s.rsc_coupons_off_percentage_off)}
                            >
                              {data?.off_text}
                            </div>
                          </div>
                        )}
                        {item?.value_type === 'fixed_amount' && (
                          <div className={s.rsc_coupons_off_fixed}>
                            <div className="flex items-baseline justify-center leading-[1]">
                              <span
                                className={cn(s.rsc_coupons_off_fixed_unit, {})}
                              >
                                {data?.unit_text}
                              </span>
                              <span
                                className={cn(s.rsc_coupons_off_fixed_num, {
                                  ['order-[-1]']: [
                                    'eu-de',
                                    'nl',
                                    'en-eu',
                                    'eu-fr',
                                    'eu-it',
                                    'eu-es',
                                    'cz-en',
                                  ].includes(locale),
                                })}
                              >
                                {Math.abs(Number(item.value))}
                              </span>
                            </div>
                            <div
                              className={cn(s.rsc_coupons_off_fixed_off, {
                                ['ml-1']: ['eu-de', 'nl', 'en-eu'].includes(
                                  locale
                                ),
                              })}
                            >
                              {data?.off_text}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={s.rsc_coupons_detail_info}>
                      <div className={s.rsc_coupons_line}>
                        <img
                          alt="line"
                          src={data?.coupon_line_img}
                          aria-hidden="true"
                        />
                      </div>
                      <div className={s.rsc_coupons_detail_info_content}>
                        <p className={s.rsc_coupons_detail_info_code}>
                          {data?.code_text}
                          <span
                            className={s.rsc_coupons_detail_info_code_value}
                          >
                            {item?.code}
                          </span>
                          <span
                            onClick={() => onCodeCopy(item?.code, index)}
                            className={s.rsc_coupons_detail_info_code_btn}
                            tag="Auto-10000639"
                          >
                            {item?.copied ? data?.copied_text : data?.copy_text}
                          </span>
                        </p>
                        <div
                          className={s.rsc_coupons_detail_info_desc_box}
                          dangerouslySetInnerHTML={{
                            __html: item?.brief || data?.no_threshold_text,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
            <li
              className={s.rsc_coupons_desc}
              dangerouslySetInnerHTML={{ __html: data?.note }}
            />
          </ul>
        </div>
        <div className={s.rsc_footer_wrap}>
          <ul className=" list-decimal [&_li]:marker:text-[13px] [&_li]:marker:font-medium [&_li]:marker:leading-[1.2] [&_li]:marker:text-[#777]">
            <li>
              <p className={s.rsc_coupons_desc}>{data.verifyTip1}</p>
            </li>
            {!showGoogleLoginCoupon && (
              <li>
                <p className={s.rsc_coupons_desc}>
                  {data.verifyTip2}
                  {countDownTime > 0 ? (
                    <>
                      <span>{data?.retrieveText}</span>
                      &nbsp;<span>{countDownTime}</span>&nbsp;
                      <span>{data?.secondsText}</span>
                    </>
                  ) : (
                    <a
                      onClick={handleSendVerifyEmail}
                      className={cn(
                        'cursor-pointer text-anker-color underline',
                        {
                          ['pointer-events-none']: submitLoading,
                        }
                      )}
                    >
                      {data?.sendEmailText}
                    </a>
                  )}
                </p>
              </li>
            )}
          </ul>
          <div className="flex gap-[12px] mt-[12px] l:flex-col-reverse">
            <button
              onClick={onCouponsGet}
              className={cn(
                s.flex_center,
                data?.klaviyo_btn
                  ? 'flex-1 py-[15px] l:py-[14px] px-[24px] leading-[1] box-border border border-[#333] text-[#333] font-[600] text-[16px] rounded-full [&:hover]:bg-[#333] [&:hover]:text-[#fff] transition-all duration-200'
                  : s.registrations_btn,
                !data?.klaviyo_btn && s.rsc_get_btn
              )}
            >
              {data.coupon_btn}
            </button>
            {data?.klaviyo_btn && (
              <button
                onClick={() => {
                  if (window?._klOnsite && data?.klaviyo_id) {
                    window._klOnsite = window._klOnsite || []
                    window._klOnsite.push(['openForm', `${data?.klaviyo_id}`])
                  }
                }}
                className={cn(s.registrations_btn, s.flex_center, 'flex-1')}
              >
                {data.klaviyo_btn}
              </button>
            )}
          </div>
        </div>
      </div>

      {resend && (
        <div className={s.rsc_tip}>
          <div className={s.rsc_tip_icon_wrap}>
            <span className={s.rsc_tip_info_icon}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_205_16388)">
                  <circle cx="10" cy="10" r="10" fill="#1677FF" />
                  <path
                    d="M10 16L10 10"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <circle
                    cx="10"
                    cy="6.5"
                    r="1.5"
                    transform="rotate(-180 10 6.5)"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_205_16388">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
          <p className={s.rsc_tip_text}>{data?.reminder_send_tip}</p>
        </div>
      )}
    </registrations-coupons>
  )
}

const Countdown = ({ s, expired_at }) => {
  // start_ai_generated
  const [showCountdown, setShowCountdown] = useState(false)
  const [hour, setHour] = useState(0)
  const [minute, setMinute] = useState(0)
  const [second, setSecond] = useState(0)
  // end_ai_generated
  useEffect(() => {
    if (!expired_at) return
    let endTime = new Date(expired_at)
    let timer = setInterval(function () {
      let now = new Date()
      let timestamp = endTime - now
      if (timestamp <= 0) {
        clearInterval(timer)
        return
      }
      let daytime = timestamp / (1000 * 60 * 60 * 24)
      let day = Math.floor(daytime)
      let hourTime = (daytime - day) * 24
      let hour = Math.floor(hourTime)
      let minuteTime = (hourTime - hour) * 60
      let minute = Math.floor(minuteTime)
      let secondTime = (minuteTime - minute) * 60
      let second = Math.floor(secondTime)
      day = day > 9 ? day : '0' + day
      hour = hour > 9 ? hour : '0' + hour
      minute = minute > 9 ? minute : '0' + minute
      second = second > 9 ? second : '0' + second
      day > 0 ? setShowCountdown(false) : setShowCountdown(true)
      // start_ai_generated
      setHour(hour)
      setMinute(minute)
      setSecond(second)
      // end_ai_generated
    }, 1000)
  }, [expired_at])
  return (
    showCountdown && (
      <div className={s.rsc_validtime_desc_wrap}>
        <div className={s.rsc_validtime_desc_item}>{hour}</div>
        <span className={s.rsc_validtime_desc_item_separate}>:</span>
        <div className={s.rsc_validtime_desc_item}>{minute}</div>
        <span className={s.rsc_validtime_desc_item_separate}>:</span>
        <div className={s.rsc_validtime_desc_item}>{second}</div>
      </div>
    )
  )
}

export default RegistrationsCoupons

import styles from './index.module.css'
import cn from 'classnames'
import { useRouter } from 'next/router'
import fetcher from '@lib/fetcher'
import { useCallback, useState } from 'react'
// start_ai_generated
import { getPassportSign } from '@lib/utils/tools'
// end_ai_generated

const RegistrationsRemainder = ({
  data,
  gs,
  rsrFillEmail,
  onReminderBack,
  onReminderGet,
}) => {
  const s = { ...styles, ...gs }
  const [resendLoading, setResendLoading] = useState(false)
  const [resendStatus, setResendStatus] = useState('')
  const { locale } = useRouter()

  const handleSendAgain = useCallback(async () => {
    setResendLoading(true)
    const bodyData = {
      email: rsrFillEmail,
      sign: getPassportSign(rsrFillEmail),
      data: btoa(
        JSON.stringify({
          redirect:
            location.href +
            (location.search ? location.search + '&' : '?') +
            'ref=quickloginauth_reg_sub_pop',
        })
      ),
    }
    const res = await fetcher({
      locale,
      url: '/api/multipass/account/quick_logins/login_token',
      method: 'POST',
      body: bodyData,
      needRecaptcha: true,
      recaptchaAction: 'login',
    }).finally(() => setResendLoading(false))
    if (res.status > 100 && res.status < 300) {
      setResendStatus('success')
    } else {
      setResendStatus('error')
    }
    let timer = setTimeout(() => {
      setResendStatus('')
      clearTimeout(timer)
    }, 5000)
  }, [locale, rsrFillEmail])

  return (
    <registrations-reminder class={s.registrations_reminder}>
      <div className={s.registrations_reminder_wrap}>
        <div className={s.registrations_reminder_head}>
          <p className={cn(s.registrations_title, s.rsr_title)}>
            {data?.reminder_title}
          </p>
          <p
            className={s.rsr_desc}
            dangerouslySetInnerHTML={{ __html: data?.reminder_desc }}
          />
          <span className={cn(s.rsr_desc, s.rsr_desc_email)}>
            {rsrFillEmail}
          </span>
        </div>
        <div className={s.registrations_reminder_body}>
          <ul
            className={s.rsr_content}
            dangerouslySetInnerHTML={{ __html: data?.reminder_content }}
          />
        </div>
        <div className={s.registrations_reminder_footer}>
          <p className={s.rsr_again_text}>{data?.reminder_again}&nbsp;</p>
          {resendLoading ? (
            <i className={s.loading_icon}>
              <svg
                t="1703486105914"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="1617"
                id="mx_n_1703486105916"
                width="15"
                height="15"
                data-spm-anchor-id="a313x.search_index.0.i4.7b043a81iM9F3b"
              >
                <path
                  d="M512 170.666667C323.477333 170.666667 170.666667 323.477333 170.666667 512s152.810667 341.333333 341.333333 341.333333 341.333333-152.810667 341.333333-341.333333h85.333334c0 235.648-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333v85.333334z"
                  p-id="1618"
                  fill="#333333"
                ></path>
              </svg>
            </i>
          ) : (
            <a onClick={handleSendAgain} className={s.rsr_send_again_text}>
              {data?.send_again_text}
            </a>
          )}
          <div className={s.rsr_btn_wrap}>
            <button
              onClick={onReminderBack}
              className={cn(s.rsr_btn, s.registrations_btn, s.rsr_btn_again)}
            >
              {data.reminder_return}
            </button>
            <button
              onClick={onReminderGet}
              className={cn(s.rsr_btn, s.registrations_btn, s.rsr_btn_get)}
            >
              {data?.reminder_get}
            </button>
          </div>
        </div>
        {resendStatus && (
          <div className={s.rsr_tip}>
            <div className={s.rsr_tip_icon_wrap}>
              {resendStatus === 'success' && (
                <span className={s.rsr_tip_info_icon}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_205_16388)">
                      <circle cx="10" cy="10" r="10" fill="#1677FF" />
                      <path
                        d="M10 16L10 10"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <circle
                        cx="10"
                        cy="6.5"
                        r="1.5"
                        transform="rotate(-180 10 6.5)"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_205_16388">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              )}
              {resendStatus === 'error' && (
                <span className={s.rsr_tip_error_icon}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_205_16236)">
                      <circle cx="10" cy="10" r="10" fill="#F84D4F" />
                      <path
                        d="M10 5L10 11"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <circle cx="10" cy="14.5" r="1.5" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_205_16236">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              )}
            </div>
            <p className={s.rsr_tip_text}>
              {resendStatus === 'success'
                ? data?.reminder_send_tip
                : data?.reminder_lock_tip}
            </p>
          </div>
        )}
      </div>
    </registrations-reminder>
  )
}

export default RegistrationsRemainder

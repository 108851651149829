import { sha256 } from '@lib/utils/tools'

const RegistrationsTrack = {
  pvTrack() {
    if (window.dataLayer) {
      window.dataLayer.push({ event_parameters: null })
      window.dataLayer.push({
        event: 'ga4Event',
        event_name: 'user_center',
        event_parameters: {
          page_group: 'reg_sub_pop',
          position: '',
          button_name: 'impression',
        },
      })
    }
  },
  closeTrack({ position, page_group, button_name }) {
    if (window.dataLayer) {
      window.dataLayer.push({ event_parameters: null })
      window.dataLayer.push({
        event: 'ga4Event',
        event_name: 'user_center',
        event_parameters: {
          page_group,
          position,
          button_name,
        },
      })
    }
  },
  registrationsTrack({ action, subscribe, email, deals_type, fbqTrack }) {
    const sha256Email = email ? sha256(email.toLowerCase()) : ''
    if (window.dataLayer) {
      window.dataLayer.push({ event_parameters: null })
      window.dataLayer.push({
        user_hashed_email: sha256Email, //邮箱加密算法sha256，在注册成功事件上报前推送
      }) //标黄代码须在下方代码前
      window.dataLayer.push({
        event: 'ga4Event',
        event_name: 'user_center',
        event_parameters: {
          page_group: 'reg_sub_pop',
          position: 'register',
          button_name: '',
          subscribe_box: subscribe, //订阅勾选框勾选状态取1，没有勾选取0
          action: action, //fail表示注册失败，success表示注册成功
        },
      })
      if (subscribe) {
        window.dataLayer.push({ event_parameters: null })
        window.dataLayer.push({
          subscribe_hashed_email: sha256Email, //推订阅的加密邮箱，加密算法sha256-20230726新增
        }) //标黄代码须在下方代码前
        window.dataLayer.push({
          event: 'ga4Event',
          event_name: 'subscribe',
          event_parameters: {
            page_group: deals_type, //如果没有deals_type，且是底部订阅则传footer，其他情况留空
            position: 'reg_sub_pop', //传位置标记，比如首屏传masterbanner、弹窗传pop_up、最后一屏传lastbanner、悬浮框传sticky
          },
        })
        dataLayer.push({
          event: 'uaEvent',
          eventCategory: 'subscribe',
          eventAction: deals_type, //如果没有deals_type，且是底部订阅则传footer，其他情况留空
          eventLabel: 'reg_sub_pop', //传位置标记，比如首屏传masterbanner、弹窗传pop_up、最后一屏传lastbanner、悬浮框传sticky
          nonInteraction: false,
        })
      }
    }
    if (window?.fbq && fbqTrack) {
      window.fbq && fbq('trackCustom', fbqTrack)
    }
  },
  useCodeTrack() {
    window.dataLayer.push({ event_parameters: null })
    window.dataLayer.push({
      event: 'ga4Event',
      event_name: 'user_center',
      event_parameters: {
        page_group: 'reg_sub_pop',
        position: 'success',
        button_name: 'copy_code',
      },
    })
  },
  loginTrack({ position, fbqTrack }) {
    window.dataLayer.push({ event_parameters: null })
    window.dataLayer.push({
      event: 'ga4Event',
      event_name: 'user_center',
      event_parameters: {
        page_group: 'login_pop',
        position,
      },
    })
    if (window?.fbq && fbqTrack) {
      window.fbq && fbq('trackCustom', fbqTrack)
    }
  },
  loginWithThirdTrack({ typeText }) {
    window.dataLayer.push({ event_parameters: null })
    window.dataLayer.push({
      event: 'ga4Event',
      event_name: 'user_center',
      event_parameters: {
        page_group: 'login_pop',
        position: 'login_' + typeText, // $按钮文案(社媒均小写处理)
      },
    })
  },
  backRegistrationsTrack({ buttonName }) {
    if (window.dataLayer) {
      window.dataLayer.push({ event_parameters: null })
      window.dataLayer.push({
        event: 'ga4Event',
        event_name: 'user_center',
        event_parameters: {
          page_group: 'login_pop',
          position: '',
          button_name: buttonName, //关闭按钮传close
        },
      })
    }
  },
}

export default RegistrationsTrack
